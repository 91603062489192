import React from "react"

import Header from "../../layouts/Header.Fi"
import Footer from "../../layouts/Footer.Fi"

import Button from "../../components/Button"
import SEO from "../../components/SEO"

import Astronaut from "../../images/astronaut-illustration.svg"
import Businessman from "../../images/businessman-illustration.svg"

export const IndexPage = () => (
  <div className="flex flex-col min-h-screen font-sans text-gray-900">
    <SEO
      keywords={[`kopernicus`, `tuki`, `spesialisti`, `koodarijäbä`]}
      title="Etusivu"
    />

    <Header />

    <main className="flex-1 w-full max-w-screen-xl px-4 py-16 mx-auto sm:px-8">
      <section className="flex flex-wrap items-center text-center">
        <div className="w-full sm:w-1/2 px-4 mb-8">
          <h1 className="mb-8">
            Moderni IT-spesialisti seuraavan projektisi tueksi.
          </h1>
          <h3 className="mb-8">
            Toteutamme asiakaskohtaisina ratkaisuina laskentaan ja
            tiedonkeruuseen tarvittavia ohjelmistoja.
          </h3>
          <Button to="/fi/ota-yhteytta">Ota yhteyttä</Button>
        </div>

        <Astronaut className="w-full sm:w-1/2 px-4 mb-8" />
      </section>

      <section className="flex flex-wrap items-center text-center">
        <Businessman className="w-full sm:w-1/2 px-4 mb-8 order-2 md:order-1" />

        <div className="w-full sm:w-1/2 px-4 mb-8 order-1 md:order-2">
          <h2 className="mb-8">M-Power</h2>
          <h3 className="mb-8">
            M-Power on yrityssuunnitteluohjelmisto, navigaattori, jonka avulla
            saat yrityksellesi realistiset tuloslaskelmaennusteet koko
            tilikaudelle.
          </h3>
          <Button to="/fi/m-power">Lue lisää</Button>
        </div>
      </section>
    </main>

    <Footer />
  </div>
)

export default IndexPage
